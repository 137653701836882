<template>
    <div>
        <el-row align="middle" type="flex" style="margin-bottom:3px" justify="space-around">
            <el-col :span="4">
                <div>{{ $t("lang.选择日期") }}</div>
            </el-col>
            <el-col :span="16"><el-date-picker v-model="searchRange" @change="loadData" type="datetimerange" :picker-options="pickerOptions" :range-separator="$t('lang.至')" :start-placeholder="$t('lang.开始日期')" :end-placeholder="$t('lang.结束日期')" align="center" :clearable="false">
                </el-date-picker>
            </el-col>
        </el-row>
        <vxe-table ref="xTable" :loading="tableLoading" @edit-closed="editClosed" @edit-activated="editActivated" :data="sensorData" border :row-config="{ isHover: true }" :edit-config="editConfig" height="500" show-overflow="tooltip">
            <vxe-column field="dataTime" :title="$t('lang.返回时间')">
                <template #edit="{ row }">
                    <vxe-input v-model="row.dataTime" type="text"></vxe-input>
                </template>
            </vxe-column>
            <vxe-column field="temperature" align="right" :title="$t('lang.温度')" :edit-render="{ autofocus: '.vxe-input--inner' }">
                <template #edit="{ row }">
                    <vxe-input v-model.number="row.temperature" type="text"></vxe-input>
                </template>
            </vxe-column>
            <vxe-column :title="$t('lang.删除')" align="center" width="80">
                <template #default="{ row }">
                    <vxe-button type="text" status="danger" icon="vxe-icon-delete" circle @click="deleteSensorData(row)"></vxe-button>
                </template>
            </vxe-column>
        </vxe-table>
    </div>
</template>

<script>
import moment from "moment";
export default {
    props: ["sensorId"],
    data() {
        let that = this;
        return {
            editConfig: { trigger: "dblclick", mode: "cell" },
            tableLoading: false,
            sensorData: [],
            sensorModel: {},
            searchRange: [
                moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                moment().startOf("day").add(1, "day").format("YYYY-MM-DD HH:mm:ss"),
            ],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "1" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: "2" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").add(-1, "days").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: "3" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").add(-2, "days").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: "7" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").add(-6, "days").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: "15" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").add(-14, "days").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                    {
                        text: "30" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [
                                moment().startOf("day").add(-29, "days").toDate(),
                                moment().startOf("day").add(1, "days").toDate(),
                            ]);
                        },
                    },
                ],
            },
            editValueBeFore: null,

        };
    },
    methods: {
        deleteSensorData(row) {
            let that = this;
            that.$vxe.modal.confirm(that.$t("lang.确定要删除吗")).then((type) => {
                if (type == "confirm") {
                    that.axios
                        .post("WXCW81/DeleteSensorData", {
                            sensorId: that.sensorId,
                            dataTime: row.dataTime,
                        })
                        .then((response) => {
                            if (response.data.code == 101) {
                                that.$refs.xTable.remove(row);
                            }
                        });
                }
            });
        },
        editActivated({ row, column }) {
            let that = this;
            that.editValueBeFore = row[column.field];
        },
        editClosed({ row, column }) {
            let that = this;
            if (that.editValueBeFore == row[column.field]) return;
            that.axios
                .post("WXCW81/UpdateSensorData", {
                    sensorId: that.sensorId,
                    dataTime: row.dataTime,
                    temperature: row.temperature = Math.floor(row.temperature * 10) / 10.0,
                })
                .then((response) => {
                    if (response.data.code == 101) {
                        that.$message.success(that.$t("lang.修改成功"));
                    } else {
                        that.$message.error(that.$t("lang.修改失败"));
                    }
                })
                .catch(() => {
                    that.$message.error(that.$t("lang.修改失败"));
                });
        },
        loadData() {
            let that = this;
            that.tableLoading = true;
            that.axios
                .post("WXCW81/GetOneSensorData", {
                    sensorId: that.sensorId,
                    beginDate: that.searchRange[0],
                    endDate: that.searchRange[1],
                })
                .then((response) => {
                    that.sensorData = response.data.data;
                    that.tableLoading = false;
                });
        },
    },
    mounted() {
        let that = this;
        that.tableLoading = true;
        that.axios
            .post("WXCW81/GetOneSensorData", {
                sensorId: that.sensorId,
                beginDate: that.searchRange[0],
                endDate: that.searchRange[1],
            })
            .then((response) => {
                that.sensorData = response.data.data;
                that.tableLoading = false;
            });
    },
};
</script>

<style scoped></style>