<template>
    <div>
        <div style="height:1px;background-color:#DCDFE6;margin: 20px 0px;"></div>
        <el-row :gutter="5">
            <el-col :span="10">
                <el-divider>{{ $t("lang.测点绑定") }}</el-divider>
                <el-form ref="elform" label-width="150px" :model="formModel" :rules="rules">
                    <el-form-item :label="$t('lang.传感器序列号')" prop="sensorNumber">
                        <el-input v-model="formModel.sensorNumber" @change="handChange"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('lang.测点名称')" prop="sensorName">
                        <el-input v-model="formModel.sensorName"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="bindSensorNumber(0)">{{ $t("lang.绑定") }}</el-button>
                        <el-button @click="$refs.elform.resetFields()">{{ $t("lang.重置") }}</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-tag v-show="tagCode == 101" size="medium">{{ tagText }}</el-tag>
                        <el-tag v-show="tagCode == 201" type="danger" size="medium">{{ tagText }}</el-tag>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="14">
                <el-divider>{{ $t("lang.已绑定") }}</el-divider>
                <vxe-table :loading="tableLoading" :data="tableData" border :row-config="{ isHover: false }">
                    <vxe-column field="sensorNumber" :title="$t('lang.传感器序列号')" width="150"></vxe-column>
                    <vxe-column field="sensorName" :title="$t('lang.测点名称')"></vxe-column>
                    <vxe-column :title="$t('lang.操作')" width="350" align="center"><template #default="{ row }">
                            <vxe-button type="text" status="primary" icon="vxe-icon-edit" circle @click="handleEdit(row)">{{ $t("lang.编辑名称") }}</vxe-button>
                            <vxe-button type="text" status="success" icon="vxe-icon-eye-fill" circle @click="handleViewData(row)">{{ $t("lang.查看数据") }}</vxe-button>
                            <vxe-button type="text" status="danger" icon="vxe-icon-delete" circle @click="unBindSensorNumber(row)">{{ $t("lang.解除绑定") }}</vxe-button>
                        </template></vxe-column>
                </vxe-table>
            </el-col>
        </el-row>
        <el-dialog :visible.sync="editVisible" :title="$t('lang.编辑')" width="30%" :show-close="false" :close-on-click-modal="false">
            <el-form ref="formEdit" label-width="150px" :model="formEditModel" :rules="editRules" label-position="left">
                <el-form-item :label="$t('lang.测点名称')" prop="sensorName">
                    <el-input v-model="formEditModel.sensorName"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="editVisible = false">{{ $t("lang.取消") }}</el-button>
                <el-button type="primary" @click="saveCD">{{ $t("lang.保存") }}</el-button>
            </template>
        </el-dialog>
        <vxe-modal v-model="viewVisible" :title="viewSensorName" width="600">
            <Dialog_WXCW81_OneSensor :sensorId="viewSensorId" v-if="viewVisible"></Dialog_WXCW81_OneSensor>
        </vxe-modal>

    </div>
</template>

<script>
import Dialog_WXCW81_OneSensor from './Dialog_WXCW81_OneSensor.vue';

export default {
    components: { Dialog_WXCW81_OneSensor },
    data() {
        let that = this;
        return {
            viewSensorId: null,
            viewSensorName: "",
            formModel: { ifCover: 0 },
            formEditModel: {},
            rules: {
                sensorNumber: [{ required: true, message: that.$t("lang.不能为空") },],
                sensorName: [{ required: true, message: that.$t("lang.不能为空") },],
            },
            editRules: {
                sensorName: [{ required: true, message: that.$t("lang.不能为空") },],
            },
            tagCode: "",
            tagText: "",
            tableLoading: false,
            tableData: [],
            editVisible: false,
            viewVisible: false,

        };
    },

    methods: {
        handleEdit(row) {
            let that = this;
            that.formEditModel = Object.assign({}, row);
            that.editVisible = true;
        },
        handleViewData(row) {
            let that = this;
            that.viewSensorId = row.id;
            that.viewSensorName = row.sensorName;
            that.viewVisible = true;
        },
        saveCD() {
            let that = this;
            that.$refs.formEdit.validate((v) => {
                if (v) {
                    that.axios.post("WXCW_WXCWSensor/EditModel", that.formEditModel).then((response) => {
                        if (response.data.code == 101) {
                            that.editVisible = false;
                            that.$message.success(that.$t("lang.保存成功"));
                            that.GetUserSensorList();
                        }
                    });
                }
            });
        },
        handChange(v) {
            let that = this;
            that.axios.post("WXCW_WXCWSensor/GetModelBySensorNumber", { onlyText: v }).then((response) => {
                if (response.data.data) {
                    that.formModel = response.data.data;
                }
            });
        },
        bindSensorNumber(ifCover) {
            let that = this;
            that.formModel.ifCover = ifCover;
            that.tagText = that.tagCode = "";
            that.$refs.elform.validate((valid) => {
                if (valid) {
                    that.axios
                        .post("WXCW81/BindSensorNumber", that.formModel)
                        .then(function (response) {
                            if (response.data.code == 202) {
                                that.$confirm(that.$t("lang.确定要覆盖吗") + "(" + that.formModel.sensorName + ")-->(" + response.data.data + ")", {
                                    confirmButtonText: that.$t("lang.是"),
                                    cancelButtonText: that.$t("lang.否"),
                                    showClose: false,
                                }).then(() => {
                                    that.bindSensorNumber(1);
                                }).catch(() => { that.bindSensorNumber(2); });
                            } else {
                                that.GetUserSensorList();
                                that.tagCode = response.data.code;
                                that.tagText = response.data.msg;
                            }
                        });
                }
            });
        },
        unBindSensorNumber(row) {
            let that = this;
            that.$confirm(that.$t("lang.确定要删除吗"))
                .then(function () {
                    that.axios.post("WXCW81/UnBindSensorNumber", {
                        id: row.id //这里id是sensorId
                    }).then((response) => {
                        if (response.data.code == 101) {
                            that.$message({ type: "success", message: that.$t("lang.删除成功") });
                            that.GetUserSensorList();
                        }
                        else {
                            that.$message({ type: "error", message: that.$t("lang.其他人绑定中") });
                        }
                    });
                })
                .catch(() => { });
        },
        GetUserSensorList() {
            let that = this;
            that.tableLoading = true;
            that.axios.post("WXCW81/GetUserSensorList", {}).then((response) => {
                that.tableLoading = false;
                that.tableData = response.data.data;
            });
        }

    },
    mounted() {
        let that = this;
        that.GetUserSensorList();

    },
};
</script>

<style scoped></style>